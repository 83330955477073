/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import Layout from "@layout";
import PageBreadcrumb from "@components/pagebreadcrumb";
import { graphql, Link } from "gatsby";
import { Row, Container, Col } from "react-bootstrap";
import StayInTouchs from "@components/stay-in-touch";
import LatestPostArea from "../../container/latest-post";
import PostAuthorBox from "../../components/post-author";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import {
    SingleBlogContent,
    BlogDetailsArea,
    Thumb,
    Content,
    BlogDetailsMetaBox,
    PostMetaLeftSide,
    BlogDetailsPostAuthor,
    PostMidSide,
    PostDate,
    PostTime,
    PostMetaRightSide,
    Title,
} from "./style";

const SinglePosts = ({ data, location, pageContext }) => {
    const new_Data = data.allStrapiArticle.edges;

    const image = getImage(
        new_Data[0].node.image.localFile.childrenImageSharp[0]
    );
    function formatDate(date) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }
    const date = formatDate(new_Data[0].node.updated_at);
    return (
        <Layout>
            <PageBreadcrumb pageContext={pageContext} location={location} />
            <BlogDetailsArea>
                <Container>
                    <Row className="gx-5">
                        <Col lg={8}>
                            <Thumb>
                                <GatsbyImage
                                    image={image}
                                    alt={new_Data[0].node.title}
                                />
                            </Thumb>
                            <Content>
                                <BlogDetailsMetaBox>
                                    <PostMetaLeftSide>
                                        <BlogDetailsPostAuthor>
                                            By{" "}
                                            <Link
                                                to={`/profile/${new_Data[0].node.author.slug}/`}
                                            >
                                                {new_Data[0].node.author.name}
                                            </Link>
                                        </BlogDetailsPostAuthor>
                                    </PostMetaLeftSide>

                                    <PostMidSide>
                                        <PostDate>
                                            <i className="icofont-ui-calendar"></i>
                                            {date}
                                        </PostDate>
                                        <PostTime>10 min read</PostTime>
                                    </PostMidSide>

                                    <PostMetaRightSide>
                                        <a href="#">
                                            <StaticImage
                                                src="../../data/images/icons/small-bookmark.png"
                                                alt=""
                                            />
                                        </a>
                                        <a href="#">
                                            <StaticImage
                                                src="../../data/images/icons/heart.png"
                                                alt=""
                                            />
                                        </a>
                                    </PostMetaRightSide>
                                </BlogDetailsMetaBox>

                                <Title>{new_Data[0].node.title}</Title>
                                <SingleBlogContent
                                    dangerouslySetInnerHTML={{
                                        __html: new_Data[0].node.content,
                                    }}
                                />
                            </Content>
                        </Col>
                        <Col lg={4}>
                            <div className="blog-details-sidebar">
                                {new_Data && (
                                    <PostAuthorBox
                                        postAuthorName={
                                            new_Data[0].node.author.name
                                        }
                                        postAuthorImage={
                                            new_Data[0].node.author.picture
                                                .localFile
                                        }
                                        postAuthorBio={
                                            new_Data[0].node.author.name
                                        }
                                        postAuthordescription={
                                            new_Data[0].node.author.email
                                        }
                                        authorSlug={
                                            new_Data[0].node.author.slug
                                        }
                                    />
                                )}
                                <LatestPostArea />
                                <StayInTouchs />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </BlogDetailsArea>
        </Layout>
    );
};

SinglePosts.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
};

export default SinglePosts;

export const blogPostBySlug = graphql`
    query blogPostBySlug($slug: String) {
        allStrapiArticle(filter: { slug: { eq: $slug } }) {
            edges {
                node {
                    slug
                    id
                    title
                    content
                    description
                    updated_at
                    author {
                        id
                        name
                        slug
                        email
                        picture {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        layout: FIXED
                                        width: 80
                                        height: 80
                                        quality: 100
                                    )
                                }
                            }
                        }
                    }
                    image {
                        localFile {
                            childrenImageSharp {
                                gatsbyImageData(
                                    width: 850
                                    height: 400
                                    quality: 100
                                )
                            }
                        }
                    }
                    category {
                        name
                        slug
                    }
                }
            }
        }
    }
`;
